import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponents/TitleLabel'
import { Switch, TablePagination } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { getRegisteredUserListAPIURL, updateUserStatusAPIURL } from '../Services/RegisteredUserListAPI';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 500,
        fontSize: "12px"
    },
    toolbar: {

        padding: "0px",
        margin: "0px"
    },
    menuItem: {
        fontSize: "12px",
        padding: "4px"
    },
    displayedRows: {
        fontSize: "12px",
        padding: "0px",
        margin: "-25px"
    },

    selectLabel: {
        fontSize: "12px",
        padding: "0px",
        margin: "0px -4px 0px 6px "
    },
    spacer: {
        padding: "0px",
        margin: "0px",
        flex: 'none'
    },
    input: {
        fontSize: "5px",
        padding: "0px",
        margin: "0px"
    },
    root: {
        padding: "0px",
        margin: "0px"
    }
});

const UserList = () => {
    const titleName = "User List"

    const [data, setData] = useState([]);
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [status, setStatus] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const data1 = sessionStorage.getItem('token');

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading = [
        { label: 'Sr.No', key: 'id' },
        { label: `User Name`, key: 'name' },
        { label: `Email`, key: 'email' },
        { label: `Mobile No.`, key: 'mobile_no' },
        { label: 'Status', key: 'status', value: 'status', isAction: true },
    ]

    const [filteredData, setFilteredData] = useState(data)
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const getData = () => {
        // console.log(getRegisteredUserListAPIURL)
        axios
            .get(getRegisteredUserListAPIURL, {
                headers: {
                    'token': data1
                }
            })
            .then((res) => {
                setData(res.data);
                setFilteredData(res.data);
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    (item.id?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.name?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.mobile_no?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.status?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.email?.toLowerCase().includes(searchTerm.toLowerCase()) || '')
            )
        );
    }, [searchTerm, data]);

    const handleToggleStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between active/inactive
        // console.log(`${updateUserStatusAPIURL}?id=${id}&status=${newStatus}`);

        axios
            .put(
                `${updateUserStatusAPIURL}?id=${id}&status=${newStatus}`,
                {}, // Empty body since you're sending query parameters
                {
                    headers: {
                        'token': data1
                    } // Correct way to pass the token
                }
            )
            .then((res) => {
                // console.log(res.data);
                if (res.data === "UPDATED") {
                    getData();
                    toast.success("Status Updated Successfully");
                }
            })
            .catch((err) => console.log("Error updating status:", err));
    };




    return (
        <>
            <div className="container">

                <TitleLabel titleName={titleName} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 2px 15px #CECECE", marginTop: "16px", marginBottom: "20px" }}>

                    <div className='row mt-2'>
                        <div >
                            <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setSearchTerm(event.target.value); }}
                            />
                        </div>
                    </div>

                    <div className="table-responsive mt-2">
                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#EDC3FF", backgroundImage: "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)" }}>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <th
                                                style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={index}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: "2px solid #D8EDF7" }}>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.id}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.name}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.email}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.mobile_no}
                                            </td>
                                            <td style={{ width: "100px", width: '150px' }}>
                                                <Switch
                                                    defaultChecked={item.status === 1} // ON when status is 1
                                                    onChange={() => handleToggleStatus(item.id, item.status)}
                                                    color="primary"
                                                />
                                            </td>

                                        </tr>
                                    ))}
                            </tbody>


                            <tfoot >
                                <tr style={{}}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>
            </div >

            <ToastContainer theme='colored' />
        </>
    )
}

export default UserList
