import React from 'react'

import Login from '../Login';
import { Route, Routes } from 'react-router-dom';

import Dashboard from '../components/Dashboard';

import MuhartaMaster from '../AllComponents/Master/MuhartaMaster';
import PartyMaster from '../AllComponents/Master/PartyMaster';
import ModelMaster from '../AllComponents/Master/ModelMaster';
import CityMaster from '../AllComponents/Master/CityMaster';
import CountryMaster from '../AllComponents/Master/CountryMaster';
import WeekDayMaster from '../AllComponents/Master/WeekDayMaster';
import TimeMaster from '../AllComponents/Master/TimeMaster';
import RashiMaster from '../AllComponents/Master/RashiMaster';
import YearMaster from '../AllComponents/Master/YearMaster';
import TypeMaster from '../AllComponents/Master/TypeMaster';
import MarathiMonthMaster from '../AllComponents/Master/MarathiMonthMaster';

import PatrikaEntryTable from "../AllComponents/PatrikaEntryTable";
import PatrikaEntryForm from "../AllComponents/PatrikaEntryForm";
import MDEMaster from '../AllComponents/MuhurtaDataEntry/MDEMaster';
import Report from '../AllComponents/Report';
import PatrikaCreatedForm from '../AllComponents/PatrikaCreatedForm';
import PatrikaDeliverForm from '../AllComponents/PatrikaDeliverForm';
import ContactDetails from '../CommonComponents/ContactDetails';
import OnlinePatrikaEntryTable from '../AllComponents/OnlinePatrikaEntryTable';
import OnlinePatrikaEntryForm from '../AllComponents/OnlinePatrikaEntryForm';
import GunMilanDataTable from '../AllComponents/GunMilan/GunMilanDataTable';
import GunMilanShowUserData from '../AllComponents/GunMilan/GunMilanShowUserData';
import DailyReportForm from '../AllComponents/DailyReport/DailyReportForm';
import UserList from '../AllComponents/RegisteredUserList/UserList';


const MasterContent = () => {


  return (
    <>
      <Routes>
        {/* <Route path="/contactDetails" element={<ContactDetails />} /> */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard/" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />

        <Route path="/m001/" element={<MuhartaMaster />} />
        <Route path="/m002/" element={<PartyMaster />} />
        <Route path="/m003/" element={<ModelMaster />} />
        <Route path="/m004/" element={<CityMaster />} />
        <Route path="/m005/" element={<CountryMaster />} />
        <Route path="/m006/" element={<WeekDayMaster />} />
        <Route path="/m007/" element={<TimeMaster />} />
        <Route path="/m008/" element={<RashiMaster />} />
        <Route path="/m009/" element={<YearMaster />} />
        <Route path="/m010/" element={<TypeMaster />} />
        <Route path="/m011/" element={<MarathiMonthMaster />} />
       

        <Route path="/b001/" element={<Report />} />
        <Route path='/pt001/' element={<PatrikaEntryTable />} />
        <Route path='/pf001/' element={<PatrikaEntryForm />} />
        <Route path='/pf002/' element={<PatrikaCreatedForm />} />
        <Route path='/pf003/' element={<PatrikaDeliverForm />} />

        <Route path='/opt001/' element={<OnlinePatrikaEntryTable />} />
        <Route path='/opf001/' element={<OnlinePatrikaEntryForm />} />

        <Route path='/gm001/' element={<GunMilanDataTable />} />
        <Route path='/gm002/' element={<GunMilanShowUserData />} />

        <Route path='/mde001/' element={<MDEMaster />} />

        <Route path='/dr001/' element={<DailyReportForm />} />

        <Route path='/ul001/' element={<UserList />} />


      </Routes>
    </>
  )
}

export default MasterContent